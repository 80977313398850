#service{
    background-color: orange;
    height: auto;
    margin-top: 50px;
}
#h12{
    font-size: 5rem;
    font-family: 'Yeon Sung', cursive;
    color: white;
    text-align: center;
}

.div{
    height: 14rem;
    width: 21rem;
    display: inline-block;
    background-color: aqua;
    margin: 20px;
    margin-left: 80px;
    margin-top: 4rem;
    border: 5px solid white;
    background-repeat: no-repeat;
    background-size: cover; 
}

#div1{
    background-image: url('../img/wedding.jpg');  
}
#div2{
    background-image: url('../img/party.jpg');
}
#div3{
    background-image: url('../img/birth.jpg');
}

#div4{
    margin-left: 20rem;
    margin-bottom: 4rem;
    background-image: url('../img/event.jpg');
}

#div5{
    margin-bottom: 4rem;
    background-image: url('../img/custom.avif');
}

.div:hover{
    transform: scale(1.25); 
    cursor: pointer;
    opacity: 1;
}


.span{
    z-index: 1;
    color: white;
    font-size: 4.5rem;
    font-family: 'Yeon Sung', cursive;
    margin-left: 30px;
    /* margin-top: 80px; */
    opacity: 0.3;
}
.span:hover{
    opacity: 1;
}

/* when width will decrease from 1307 it will remove the decrease the margin left */
@media screen and (max-width:1307px) {
    #div4{
    margin-left: 80px;
    }
}