.social-media{
    background-color: orange;
    margin-top: 50px;
}
#h1{
    font-size: 5rem;
    font-family: 'Yeon Sung', cursive;
    color: white;
    text-align: center;
}
.icons{
    /* background-color: white; */
    width: 15vw;
    height: 15vh;
    margin: 40px;
    text-align: center;
    display: inline-block;
    margin-left: 70px;
}
.contact-fa{
    font-size: 5rem;
    margin-top:20px ;
    color: white;
}

.contact-fa:hover{
    transform: scale(1.5);
    color: teal;
    cursor: pointer;
}