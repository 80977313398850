#eating-mood{
  /* background-color: yellow; */
  background-color: orange;
  height: auto;
  width: auto;
  margin-top: 50px;
}

#h11{
  text-align: center;
  font-family: 'Yeon Sung', cursive;
  color: white;
  font-weight: bolder;
  font-size: 3rem;
  padding-top: 50px;
  padding-bottom: 50px;
}

.card-div{
  height:auto;
  width: 20vw;
  display: inline-block;
  /* background-color: aqua; */
  margin-left: 50px;
}

.card-img-top{
  height: 12rem;
  width: auto;
}

.card-body{
  background-color: white;
  padding: 50px;
  margin-bottom: 5rem;
  text-align: center;
}

.card-title{
padding: 20px;
font-family: 'Yeon Sung', cursive;
}

.card-text{
    font-family: 'Yeon Sung', cursive;
    padding: 0px 20px;
}

.btn{
    margin: 20px;
    font-family: 'Yeon Sung', cursive;
    text-align: center;
}

@media screen and (max-width: 768px) {
  .card-div{
    height: 5vh;
    width: 35vw;
  }
  .card-title{
    font-size: 20px;
  }
  .card-text{
    padding: 0;
    font-size: 10px;
  }
  .card-img-top{
    padding-top: 50px;
  }
  .btn{
    font-size: 5px;
  }
}