*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Yeon Sung', cursive;
}
a{
    text-decoration: none;
}
li {
    list-style: none;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: teal;
    /* background-color: orangered; */
    color: #fff;
   }
   .nav-links a {
    color: #fff;
  text-decoration: none;
  font-family: 'Yeon Sung', cursive;

   }
   /* LOGO */
   .logo {
    border-radius: 100%;
   }
   /* NAVBAR MENU */
   .menu {
    display: flex;
    gap: 1em;
    /* font-size: 18px; */
    font-size: 1.5rem;
   }
   .menu li:hover {
    background-color: #4c9e9e;
    border-radius: 5px;
    transition: 0.3s ease;
   }
   .menu li a:hover {
    color: #fff;
   }
   .menu li {
    padding: 5px 14px;
   }

   /*RESPONSIVE NAVBAR MENU STARTS*/
/* CHECKBOX HACK */
input[type=checkbox]{
    display: none;
   } 
   /*HAMBURGER MENU*/
   .hamburger {
    display: none;
    font-size: 24px;
    user-select: none;
   }
   /* APPLYING MEDIA QUERIES */
   @media (max-width: 768px) {
   .menu { 
    display:none;
    position: absolute;
    background-color:teal;
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
   }
   .menu li:hover {
    display: inline-block;
    background-color:#4c9e9e;
    transition: 0.3s ease;
   }
   .menu li + li {
    margin-top: 12px;
   }
   input[type=checkbox]:checked ~ .menu{
    display: block;
   }
   .hamburger {
    display: block;
   }
   
   }

   @media screen and (max-width: 768px) {
    .logo{
      height: 50px;
      width: 50px;  
    }

}