*{
  /* background-color: yellow; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Yeon Sung', cursive;
}
/* header{
  background-color: orangered;
  display: flex;
  justify-content: space-between;
}

.logo{
  border-radius: 100px;
}

.header-menu{
  list-style: none;
  display: flex;
  gap: 4rem;
  color: white;
  padding: 50px;
  font-size: 1.5rem;
}

.header-menu>li>a{
  list-style: none;
  text-decoration: none;
    font-family: 'Yeon Sung', cursive;
    color: yellow;
}

.header-menu>li>a:hover{
  color:white;
}
 */
